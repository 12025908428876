'use strict';

/*******************************************************************************************/
class UxrPopupSearch20 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'valueTheme': [],
			'valueFulltext': '',
		};
		
		this.onJQueryMount = this.onJQueryMount.bind(this);
		this.onChange_theme = this.onChange_theme.bind(this);
		this.onChange_fulltext = this.onChange_fulltext.bind(this);
		this.onClick_listEntry = this.onClick_listEntry.bind(this);
		
		this.filters = {
			'theme': {
				value: [],
				test: this.filter_theme.bind(this),
			},
			'fulltext': {
				value: [new RegExp('', 'i')],
				test: this.filter_fulltext.bind(this),
			},
		};
		
		
		let e = AUDIOWALKDATA_2_0.entitesByTypeNames(['v1_themenkreis']);
		this.themeFilterOptions = e.map(function(each) {
			return {
				'value': each.id,
				'label': each.title,
			};
		});
		
		GLOBALS.searchReact = this;
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let self = this;
		
		let props = {};
		props['ref'] = this.onJQueryMount;
		
		let hitList = [];
		
		let svgMarkerMarkup = (
			<img className="hitListMarker hitListMarkerEvent" src="/application/themes/rememberingKlassisch/img/marker_ereignis_full.png" />
		);
		
		let activePopupsData = this.matchingPopupsData();
		_.forEach(activePopupsData, function(p, id) {
			let props = {
				'key': id,
				'data-id': id,
				'onClick': self.onClick_listEntry,
			}
			hitList.push(
				<div className="hitListEntry" {...props}>
					{svgMarkerMarkup}
					<span>{p.title}</span>
					<div className="flowClear" />
				</div>
			);
		});
		
		GLOBALS.stateEngine.setActiveSubset_fromPopups(activePopupsData);
		
		return (
			<div className="popupContainer popupContainerList popupContainerSearch" {...props}>
				<div className="popupContainer_controls">
					<div className="popupContainer_iconClose fa fa-close" onClick={GLOBALS.stateEngine.searchClose}></div>
				</div>
				
				<div className="popupContainer_content">
					
					<div className="formTable fullWidth">
						<div className="formTableRow typesetRaw">
							<div className="formTableCell label">
								{ UxrRenderDuoLangLabel("Thema", "Subject") }:
							</div>
							<div className="formTableCell fullWidth" id="bottomControls_theme">
								<UxrEe_.Select.default placeholder="" value={this.state.valueTheme} options={this.themeFilterOptions} multi={true} clearable={true} onChange={this.onChange_theme} />
							</div>
						</div>
						<div className="formTableRow typesetRaw">
							<div className="formTableCell label">
								{ UxrRenderDuoLangLabel("Volltext", "full text") }:
							</div>
							<div className="formTableCell" id="bottomControls_fulltext">
								<input type="text" value={this.state.valueFulltext} placeholder="" onChange={this.onChange_fulltext} />
							</div>
						</div>
					</div>
					
					<div className="content">
						<div className="content_list active">
						<div className="flowRight writeCaps">trefferliste</div>
						<div className="flowClear"></div>
							<div className="scrollable">
								{hitList}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		this.jQ = jQuery(node);
		
		GLOBALS.searchSO = new POPUPCONTAINER_SO_2_0(
			this.jQ,
			GLOBALS.popupSpaceSupplier, 
			GLOBALS.stateEngine.searchHide,
			POPUPCONTAINER_VO_SEARCH_2_0,
		);
		GLOBALS.spaceObjects.push(GLOBALS.searchSO);
	}
	
	/*---------------------------------------------------------------------*/
	onChange_fulltext(event) {
		let value = event.target.value;
		this.setState({'valueFulltext': value});
	}
	/*---------------------------------------------------------------------*/
	onChange_theme(value) {
		this.setState({'valueTheme': value});
	}
	
	/*---------------------------------------------------------------------*/
	filter_fulltext(p) {
		let re = this.filters.fulltext.value;
		let result = re.reduce(function(r, v) { return r && v.test(p.fulltext); }, true);
		return !result;
	}
	
	/*---------------------------------------------------------------------*/
	filter_theme(p) {
		if (p.audio === null) {
			return true;
		}
		
		let ids = this.filters.theme.value;
		let intersection = _.intersection(ids, p.audio.thematicsIdList);
		return (intersection.length !== ids.length);
	}
	
	/*---------------------------------------------------------------------*/
	matchingPopupsData() {
		let self = this;
		let result = {};
		
		{
			let value = this.state.valueFulltext;
			value = value.replace('(', ' ');
			value = value.replace(')', ' ');
			value = value.replace('[', ' ');
			value = value.replace(']', ' ');
			value = value.replace('{', ' ');
			value = value.replace('}', ' ');
			value = value.replace('.', ' ');
			value = value.replace('+', ' ');
			value = value.replace('*', ' ');
			value = value.replace('?', ' ');
			value = value.replace('\\', ' ');
			
			this.filters.fulltext.value = value.split(' ').map(function(v) {return new RegExp(v, 'i');});
		}
		{
			let value = this.state.valueTheme;
			this.filters.theme.value = value.map(function(v) {
				return v.value;
			});
		}
		
		_.forEach(GLOBALS.popupsData, function(p, id) {
			if (p.type !== "v1_ereignis") {
				return;
			}
			
			let isFiltered = false;
			_.forEach(self.filters, function(filter) {
				isFiltered = isFiltered || filter.test(p);
			});
			
			if (!isFiltered) {
				result[id] = p;
			}
		});
		
		return result;
	}
	
	/*---------------------------------------------------------------------*/
	onClick_listEntry(event) {
		let node = jQuery(event.target).closest(".hitListEntry[data-id]");;
		let id = parseInt(node.attr('data-id'));
		GLOBALS.stateEngine.contentShow(id);
	}
} //class

